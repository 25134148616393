#continerBTNSideBar a:hover {
  text-decoration: none;

}

#InputTExtDash1 {
  width: 100%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}

#headerServy {
  height: 200px;
  width: 100%;
  background-color: #47a49c;
  display: flex;
  align-items: center;
  justify-content: center;
}

#textAreaAdd {
  width: 100%;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#ColServy {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ColServy1 {
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imgSrv {
  /* height: 200px; */
  width: 200px;

}

#emoImg {
  height: 60px;
  width: 60px;
  cursor: pointer;
}

#emoImg:hover {
  transform: scale(1.1);

}

#RoundEmoj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

#btnAddItemContiner {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnAddItem {
  width: 200px;
  height: 40px;
  background-color: #47a49c;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#btnAddItem:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#btnAddItem:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#servyFinshContiner {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#ModailDesc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18;
  font-weight: 500;
}

#headerTablePageContiner {
  direction: rtl;
  display: flex;
  width: 100%;
  min-height: 200px;
  background-color: #fafafa;
}

#cardTableContiner {
  width: 200px;
  height: 100px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#continerBTNSideBar {
  z-index: 100;
  height: 100vh;
  width: 240;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InputTExtDash111NN {
  margin-right: 20px;
  width: 80px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: #003B4B 1px solid;
  color: #000;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
  outline: none;
}

.selectPages {
  margin-right: 20px;
  width: 250px;
  height: 45px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: #003B4B 1px solid;
  color: #000;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
  outline: none;
}

#InputAddOrderContiner {
  display: flex;
  align-items: center;
  justify-content: center;
}

#AddOrderBTn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #B9512A;
  color: #fff;
  margin: 0px 5px;
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
}

#InputAddOrderLabel {
  width: 40%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000;
  border-radius: 0px 5px 5px 0px;
}

#InputAddOrder {
  width: 80%;
  min-height: 40px;
  outline: none;
  padding: 0px 10px;
  border: solid 1px #000;
  border-radius: 5px 0px 0px 5px;
}

#searchDiv {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
}

#downloadDiv {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-top: 15px;
}

#serchbtn {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 11px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  font-weight: bold;
  border-radius: 8px;

}

#searchINN {
  margin: 60px 2%;
  width: 50%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: #003B4B 1px solid;
  color: #000;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
  outline: none;
}

.DataTableContiner {
  direction: ltr;
}

#NavContinerApp {
  position: absolute;
  left: 0px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px
}

#pagnationContiner {
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 2%;
}

#ContinerInPut {
  display: flex;
  justify-content: flex-start;
  margin: 20px 30px;

}

#ContinerInPut label {
  height: 40px;
  display: flex;
  align-items: center;
  width: 33%;

}

#InputTExtDash1 {
  width: 70%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}

#titleAddAppBar {
  width: 100;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 5px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#inputAddContiner {
  /* border: 1px solid #4a7fc1; */
  direction: rtl;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 20px 20px;
}

#inputAddContinerServ {
  direction: rtl;
  width: 100%;
  min-height: calc(100vh - 200px);
  /* border: 1px solid #4a7fc1; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 20px 20px;
}

#InputTExtDash1Serv {
  width: 100%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;

}

#btnAddItemContiner {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnAddItem {
  width: 200px;
  height: 40px;
  background-color: #000;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#btnAddItem:hover,
#BTNSIDEbar:hover,
#imageCateGoryContiner:hover,
#imageCateContiner:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#btnAddItem:active,
#BTNSIDEbar:active,
#imageCateGoryContiner:active,
#imageCateContiner:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#msgDelete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

#ImageSideBare {
  height: 100vh;
  width: 238px;
  background-color: #000;
  position: absolute;

  z-index: 99;
}

#BTNSIDEbar {
  height: 25px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  border-radius: 20px;
  margin: 10px 0px;
  color: #000;
  cursor: pointer;
}

#ColCateGory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

#imageCateGoryContiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  cursor: pointer;
}

#imageCateGory {
  width: 200px;
  height: 200px;
  border-radius: 10px 10px 0px 0px;
}

#imageCateContiner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  background-color: #fff;
  height: 250px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  cursor: pointer;
}

#menuFolderCateContiner {
  position: absolute;
  right: 5px;
  top: 5px;
}

#ColCateGory a {
  text-decoration: none;
  color: #000;
}

#headerCustomerOrderContiner {
  width: 95%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(210, 132, 128, 0.4);
  border-radius: 15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin: 2.5%;
  padding: 0px 20px;
}

#AppbarContiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#SpaceAppPar {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

#ContinerReportDate {
  margin: 10px;
  background-color: rgba(80, 76, 75, 0.2);
  border-radius: 10px;
}

#ContinerReportDatePages {
  margin: 10px;
  background-color: rgba(80, 76, 75, 0.2);
  border-radius: 10px;
  padding: 10px;
}

#ContinerReportPageName {
  margin: 10px;
  background-color: #007bff;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
}

#ContinerReportDate1 {
  margin: 10px;
  background-color: rgba(80, 76, 75, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

#RowReportDate {
  margin: 0px;
}

#ColReportDate {
  display: flex;
  align-items: center;
  justify-content: center;
}

#date1,
#date2 {
  width: 90%;
  height: 40px;
  /* padding-right: 10px; */
  outline: none;
  margin: 5px 0px;
  border: #000 1px solid;
  border-radius: 5px;
  text-align: center;
}

#AddClientDeptBTN1 {
  width: 150px;
  height: 45px;
  background-color: #413cc7;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#AddClientDeptBTN12 {
  width: 150px;
  height: 45px;
  background-color: #ff4c07;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}



#AddClientDeptBTN14 {
  width: 150px;
  height: 45px;
  background-color: #c2294f;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#AddClientDeptBTN13 {
  width: 150px;
  height: 45px;
  background-color: #c2294f;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#AddClientDeptBTN {
  width: 100px;
  height: 40px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#AddClientDeptBTN:hover,
#cardHomeContiner:hover,
#ADDITEM:hover,
.BtnADDTOINitem:hover,
#BTNSendAndPrint:hover {
  transform: scale(1.03);
}

#AddClientDeptBTN:active,
#cardHomeContiner:active,
#ADDITEM:active,
.BtnADDTOINitem:active,
#BTNSendAndPrint:active {
  transform: scale(1);
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#imageItemDash {
  height: auto;
  width: 100%;
}

#InputTExtDash2 {
  width: 70%;
  height: 32px;
  /* border: #4a7fc1 solid 1px; */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

}

#RadioAppbar {
  margin-top: 40px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#ColSettingDash {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  margin: 40px 0px;
}

#cardStatstikContiner {
  height: 140px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(80, 76, 75, 0.2);
  ;
  color: #000;
}

#iconStatstik {
  height: 40px;
  width: 200px;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: rgba(80, 76, 75, 0.2);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
}

#HomeContiner {
  /* background-image: url(./assets/img/center.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-attachment: fixed;
}

#HomeContiner1 {
  min-height: 100vh;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-attachment: fixed;
}

#cardHomeContiner {
  height: 140px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(210, 132, 128, 0.2);
  color: #000;
}

#iconStatstikHome {
  height: 40px;
  width: 200px;
  border-radius: 10px;
  padding: 0px 10px;
  background-color: rgba(210, 132, 128, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: -20px;
}

#CardItemContinerr {
  width: 250px;
  height: 290px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
}

#ImageItemContiner {
  height: 140px;
  width: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(210, 132, 128, 0.2);
  color: #000;
}

.classesroot {
  direction: rtl;
  background-color: #C1A269;
}

.wating {
  background-color: #F8C471;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done {
  background-color: #dc5d35e0;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel {
  background-color: #dd6656;
  color: #fff;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#headSerachLimit {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
}

#shadoUpCardItem {
  height: 70px;
  width: 250px;
  padding: 0px 10px;
  background-image: linear-gradient(rgba(210, 132, 128, 0.01), rgba(118, 215, 196, 0.99));
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 70px;
  right: 0px;
}

#contintItemCard {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px;
  text-align: center;

}

#ADDANDSubContiner {
  height: 40px;
  width: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#ADDITEM {
  height: 40px;
  width: 40px;
  cursor: pointer;

}

.BtnADDTOINitem {
  height: 40px;
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  /* background-image: linear-gradient(to right top, #ebd9e3, #e7c9d5, #e4b8c5, #e0a8b2, #db989e, #d88a91, #d47b84, #d06c77, #cd5974, #c84473, #c12d75, #b7077a); */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
}

#ShopContinERBTn {
  height: 40px;
  width: 40px;
  position: relative;
  cursor: pointer;
}

#shopNUmBtn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: crimson;
  color: #fff;
  position: absolute;
  top: 0px;
  left: -10px;
}

#CardShopContinerr {
  width: 250px;
  height: 220px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  position: relative;
}

#PriceCardCOntiner {
  height: 30px;
  width: 130px;
  background-color: #fff;
  color: #000;
  position: absolute;
  top: 20px;
  right: 0px;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#CountCardCOntiner {
  height: 30px;
  width: 30px;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 0px;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#PrintAndTotalContiner {
  direction: rtl;
  width: 100%;
  min-height: 60px;

  /* position: fixed; */
  background-color: #eee;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

#BTNSendAndPrintContiner {
  display: flex;
}

#BTNSendAndPrint {
  height: 40px;
  width: 120px;
  cursor: pointer;
  background-color: #fff;
  color: #b7077a;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 10px;
  border-radius: 10px;
}

#totalShopCOntiner {
  height: 40px;
  min-width: 180px;
  background-color: #fff;
  color: #b7077a;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
}

#LinkNavContiner {
  margin: 0px 10px;
  display: flex;
  color: #fff;
  cursor: pointer;
}

#RightSideNavBArContiner {
  display: flex;
  /* width: 200px; */
  justify-content: space-around;
}

#LinkNavContiner:hover {
  text-decoration: none;
}

#headerCustomerOrderUserContiner {
  direction: rtl;
  width: 95%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(210, 132, 128, 0.4);
  border-radius: 15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin: 2.5%;
  padding: 0px 20px;
}

#ifmcontentstoprintshop {
  display: none;
  font-size: 50px;
  font-weight: 600px;
  height: 540.47244094px;
  width: 793.7007874px;
}

@page {
  size: auto;
}

#hederPrint {
  height: 20%;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
  padding-top: 20px;
  direction: rtl;
}

#logoPrint {
  margin-top: 20px;
  /* height:200px; */
  margin-left: -30px;
}

#linePrint {
  margin-top: 30px;
  margin-left: 35px;
  width: 760px;
  height: 3px;
  -webkit-print-color-adjust: exact;
  background-color: #000 !important;
}

#hederTablePrint {
  margin: 30px 0px;
  direction: rtl;
  text-align: center;
  min-height: 500px;
}

#hederTableTextPrint {
  width: 100%;
  font-size: 20px;

}

#students {
  margin-left: 30px;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 1px solid #000;
  width: 760px;
  direction: rtl;
  font-size: 20px;
  color: #000;
}

#students td,
#students th {
  border: 1px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {

  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726 !important;
  color: black;
}

#menuEditAndDeleteContinerCate {
  margin-top: 10px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#spinnerEdit {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#titleAddUser {
  width: 100;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4cc035;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#inputAdd2Continer {
  direction: rtl;
  margin: 20px;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

.InputTExtDash111 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#labelCOntiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

#labelCOntiner1 {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;


}

#textAreaAddUser {
  width: 260px;
  min-height: 60px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#InputTExtDash111Image {
  width: 260px;
  height: 33px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fafafa;
}

#btnAddContiner {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
}

#BtnAddUser {
  width: 100px;
  height: 40px;
  background-color: #4cc035;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  z-index: 2;
}

#lineRepo {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

}

#lineRepo1 {
  height: 3px;
  width: 15%;
  border-radius: 5px;
  background-color: #d06c77;
  margin: 0px 20px;
}

#colInfoOffice {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;

}

.reportsHr {
  width: 80%;
  border-top: 2px solid rgb(0 0 0 / 52%);
}

#dateEx {
  width: 70%;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
  text-align: right;
}

#CardInfoOfficeContiner {
  height: 840px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#BtnAddUser:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#BtnAddUser:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#MenuBtnCateCont {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 254, 254);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

.serivices {
  background-color: #4960ca;
  color: #fff;
  width: 150px;
  text-align: center;
}

.items {
  background-color: #4f9dca;
  color: #fff;
  width: 150px;
  text-align: center;
}

#MenuBtnCateContTa {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(228, 218, 218);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#navDashContiner {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  background-color: #000;
  color: #fff;
}

.📦box-szg_border-box {
  z-index: 433;
}

#Comboboxgovernment {
  text-align: right;
}

@media (max-width: 768px) {
  #PrintAndTotalContiner {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  #totalShopCOntiner {
    margin-top: 10px;
  }
}



* {
  font-family: Karam;
}

#BtnSidbarContinerOut {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#sideDivider {
  width: 2px;
  height: 80px;
  background-color: #ffffff;
}

.active1 #BtnSidbarContiner {
  border-bottom: 5px solid #fbc85c;
  padding-top: 5px;
  color: #fbc85c;
}

.active2 #BtnSidbarContiner {
  border-bottom: 5px solid #4cc035;
  padding-top: 5px;
  color: #4cc035;
}

.active3 #BtnSidbarContiner {
  border-bottom: 5px solid #7063e6;
  padding-top: 5px;
  color: #7063e6;
}

.active4 #BtnSidbarContiner {
  border-bottom: 5px solid #3d70b5;
  padding-top: 5px;
  color: #3d70b5;
}

.active5 #BtnSidbarContiner {
  border-bottom: 5px solid #38d9bb;
  padding-top: 5px;
  color: #38d9bb;
}

.active6 #BtnSidbarContiner {
  border-bottom: 5px solid #eb596d;
  padding-top: 5px;
  color: #eb596d;
}

.active7 #BtnSidbarContiner {
  border-bottom: 5px solid #e9851d;
  padding-top: 5px;
  color: #e9851d;
}

.active8 #BtnSidbarContiner {
  border-bottom: 5px solid #1dcde9;
  padding-top: 5px;
  color: #1dcde9;
}

.active9 #BtnSidbarContiner {
  border-bottom: 5px solid #72ffcb;
  padding-top: 5px;
  color: #72ffcb;
}

#BtnSidbarContinerOut:hover {
  border-bottom: 5px solid #e74e48;
  padding-top: 5px;
  color: #e74e48;
  cursor: pointer;
}

#InputTExtDash260 {
  width: 260px;
  height: 40px;
  border-radius: 5px;
  border: 1px #000 solid;
  outline: none;
  text-align: right;
  padding-right: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#ColHome {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

#CardHome1,
#CardHome2,
#CardHome3,
#CardHome4 {
  height: 100px;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  cursor: pointer;
  text-align: right;

}

#CardHome1 {
  background-color: #20c997;
}

#CardHome2 {
  background-color: #ffc107;
}

#CardHome3 {
  background-color: #ff4c07;
}

#CardHome4 {
  background-color: #0c71c1;
}

#colItem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.cardItemContiner {
  position: relative;
  width: 250px;
  height: 320PX;
}

.ImageItem {
  height: 140px;
  width: 250px;
}

#BtnAddUser {
  width: 100px;
  height: 40px;
  background-color: #4cc035;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#BtnAddUser:hover,
#AddClientDeptBTN:hover {
  transform: scale(1.04);
  transition-duration: .2s;
  transition-timing-function: linear;
}

#BtnAddUser:active,
#AddClientDeptBTN:active {
  transform: scale(1);
  transition-duration: .2s;
  transition-timing-function: linear;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#titleAddUser {
  width: 100;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4cc035;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

#inputAdd2Continer {
  direction: rtl;
  margin: 20px;
  padding: 20px 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#inputAddContiner {
  padding: 0px 20px;
  direction: rtl;
}

#ContinerInPut {
  display: flex;
  justify-content: flex-start;
  margin: 20px 30px;

}

#ContinerInPut label {
  height: 40px;
  display: flex;
  align-items: center;
  width: 33%;

}

#InputTExtDash111 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#InputTExtDash111auto {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  /* padding-left: 20px; */
  background-color: #fafafa;
}

#InputTExtDash111Image {
  width: 260px;
  height: 33px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  background-color: #fafafa;
}

#spinnerEdit {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#date1 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-left: 20px;
  background-color: #fafafa;
}

#date22 {
  width: 260px;
  height: 40px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-left: 20px;
  background-color: #fafafa;
  text-align: center;
}

#textAreaAddUser {
  width: 260px;
  min-height: 60px;
  border: 0px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-right: 20px;
  background-color: #fafafa;
}

#btnAddContiner {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
}

/* .App  >div {
  overflow: scroll;
  height: 100%;
} */
#descCardItem {
  width: 100%;
  height: 50PX;
  padding: 5px;
  text-align: center;
  overflow: hidden;
}

#btnHeaderContiner {
  height: 100px;
  width: 100%;
  display: flex;
  padding: 0px 30px;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
}

#countITemContiner {
  margin-top: 10px;
  height: 25px;
  width: 50px;
  position: absolute;
  background-color: #3d70b5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 20px 20px 0px;
}

#priceITemContiner {
  margin-top: 10px;
  height: 25px;
  width: 100px;
  position: absolute;
  right: 0px;
  background-color: #3d70b5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0px 0px 20px;
}

#ADDANDSubContiner {
  display: flex;
  justify-content: center;
  align-items: center;
}

#ADDITEM {
  margin: 10px;
  cursor: pointer;
}

#menuFolderCateContiner {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

#messageExpireContiner {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

#MenuBtnCateCont {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(189, 181, 181, 0.432);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#msgDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}

#menuEditAndDeleteContinerCate {
  margin-bottom: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#MenuBtnCateCont {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(189, 181, 181, 0.432);
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
  cursor: pointer;
}

#selectAndAddContiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#selectBtn {
  height: 25px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #3d70b5;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#CancelBtn {
  height: 25px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #cf6060;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);

}

#dayExpireContiner {
  align-items: center;
  display: flex;
}

#statusColor {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

#NavHistoryContiner {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

#ContinerReportDate {
  margin: 30px;
  min-height: 60px;
  background-color: rgba(128, 210, 172, 0.2);
  border-radius: 10px;
}

#RowReportDate {
  margin: 0px;
}

#ColReportDate {
  display: flex;
  align-items: center;
  justify-content: center;
}

#date1Repo,
#date2Repo {
  width: 220px;
  height: 40px;
  /* padding-right: 10px; */
  outline: none;
  margin: 5px 0px;
  border: #38d9bb 1px solid;
  border-radius: 5px;
  text-align: center;
}

#AddClientDeptBTN {
  width: 100px;
  height: 40px;
  background-color: #38d9bb;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

}

#ColReport {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

#CardReportContiner {
  height: 70px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #5e8fac;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
}

#CardReportContinerSafe {
  height: 70px;
  width: 96%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #5e8fac;
  color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
}

#expenseslabel {
  height: 70;
  width: 100%;
  margin: 20px 0px;
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #ff4c07;
}

#linelabelexpen {
  height: 3px;
  width: 40%;
  background-color: #ff4c07;
}

#ifmcontentstoprint,
#ifmcontentstoprintL {
  display: none;
  font-size: 50px;
  font-weight: 600px;
  height: 540.47244094px;
  width: 793.7007874px;
}


#hederPrint {
  /* height: 20%; */
  width: 96%;
  margin: 25px 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  direction: rtl;
}

#logoPrint {
  margin-top: 20px;
  /* height:200px; */
  width: 300px;
}

#linePrint {
  margin-top: 30px;
  margin: 2%;
  width: 96%;
  height: 2px;
  -webkit-print-color-adjust: exact;
  background-color: #000 !important;
}

#hederTablePrint {
  margin: 30px 0px;
  direction: rtl;
  text-align: center;
  min-height: 500px;
}

#hederTableTextPrint {
  width: 100%;
  font-size: 20px;

}

#students {
  margin-left: 30px;
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 1px solid #000;
  width: 760px;
  direction: rtl;
  font-size: 20px;
  color: #000;
}

#students td,
#students th {
  border: 1px solid #000;
  padding: 2px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {

  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffa726 !important;
  color: black;
}

#ConTENTList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

#ConTENTList1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: #000 1px solid;
  border-radius: 0 5px 5px 0;
  width: 100%;
  -webkit-print-color-adjust: exact;
  background-color: #eee !important;
}

#ConTENTList2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: #000 1px solid;
  border-radius: 5px 0 0 5px;
  width: 100%;
}

#CollL {

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

#COOON {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
  background-color: #fafafa;
  border-radius: 5px;
  border: 2px solid #5c9bdf;
  box-shadow: 0px 2px 4px -1px rgba(199, 184, 184, 0.2), 0px 4px 5px 0px rgba(199, 184, 184, 0.2), 0px 1px 10px 0px rgba(199, 184, 184, 0.2);
}

#ConTENTListN {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
}

#ConTENTList3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: #000 1px solid;
  width: 100%;
  -webkit-print-color-adjust: exact;
  background-color: #eee !important;
}

#ConTENTList4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: #000 1px solid;
  width: 100%;
}

#todo {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: #363839;
  border: 1px solid #bdc1c6;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  -webkit-transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

#todo::before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 7px;
  width: 8px;
  height: 14px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

#todo:checked {
  color: #fff;
  border-color: #06842c;
  background: #06842c;
}

#todo:checked::before {
  opacity: 1;
}

#todoLabel::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

#todoLabel {
  position: relative;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 600;
  padding: 0 0.25em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#todoLabel::before {
  position: absolute;
  content: attr(data-content);
  color: #9c9e9f;
  -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  -webkit-text-decoration-color: #363839;
  text-decoration-color: #363839;
  -webkit-transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.activationtab {
  text-align: center;
  width: 200px;
  background-color: #d2c049;
  color: #fff;
}

.deptSubTab {
  text-align: center;
  width: 200px;
  background-color: #0c4c91;
  color: #fff;
}

.deptItemTab {
  text-align: center;
  width: 200px;
  background-color: #49b347;
  color: #fff;
}

.deptManualTab {
  text-align: center;
  width: 200px;
  background-color: #db5a60;
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#text-concat {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  ;
}


form-check pb-5>input {
  height: '55px';
  width: '33px';
}

.App-link {
  color: #61dafb;
}

.idPHNo {
  background: #007bff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* @media print {
  @page {
    size: 30mm 20mm;
  }
} */
.sanaLabel {
  display: block;

  @media print {
    display: block;

  }
}

.dayle_orders {
  display: none;

  @media print {
    display: block;

  }
}

@page {
  size: auto;
}

/* @media print {
  #divcontents {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
} */